<template>
  <v-container fluid tag="section">
    <v-row align-content="center">
      <v-col
        cols="12" sm="7" md="7" lg="7"
        align-self="end" class="pb-1"
      >
       <v-btn-toggle active-class="active-filter" class="px-0 filter-dishe" borderless dense background-color="transparent" v-model="filter_status">
          <v-btn text value="todo" class="primary--text" style="padding-left: 5px !important; padding-right: 5px !important">
            Todos({{ promotions.length }})
          </v-btn>
          |
          <v-btn text value="1" class="primary--text" style="padding-left: 5px !important; padding-right: 5px !important">
            Publicadas ({{ statusCount.public }})
          </v-btn>
          |
          <v-btn text value="0" class="primary--text" style="padding-left: 5px !important; padding-right: 5px !important">
            Borrador ({{ statusCount.eraser }})
          </v-btn>
        </v-btn-toggle>
      <!--   <span>
          Todos(123) | Publicadas(100) | Borradores(23) | Ordenar
        </span> -->
      </v-col>
      <v-col
        cols="12"
        class="pb-1"
        md="5" lg="5" sm="5"
      >
        <v-text-field
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            outlined
            rounded
            dense
            color="primary"
            placeholder="Buscar promoción"
            v-model="search"
          ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat class="mt-0">
          <v-sheet class="" v-if="load_promo">
            <v-responsive max-width="900" class="mx-auto">
              <v-skeleton-loader
                ref="skeleton"
                type="table"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-responsive>
          </v-sheet>
          <!--<v-data-table
            v-else
            :headers="headers"
            :items="promotions_filter"
            class="elevation-0 striped"
            :search="search"
            :sort-by.sync="sort_promo"
            no-data-text="No se encontraron Promociones"
            no-results-text="No se encontraron Coincidencias"
            show-select
            fixed-header
            item-key="id"
            loading-text="Obteniendo Promociones"
            v-model="selected_promo"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-row v-if="$vuetify.breakpoint.smAndUp">
                <v-col cols="6" class="d-flex align-center">
                   <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn rounded small outlined color="sidebar" v-on="on" style="padding-left: 10px !important; padding-right: 10px !important">
                        Acciones en lote
                        <v-icon small right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                          <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>Eliminar</v-list-item-title>
                        </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn rounded small depressed color="sidebar" class="ml-2" style="padding-left: 10px !important; padding-right: 10px !important">
                    Aplicar
                  </v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn rounded small outlined color="sidebar" v-on="on" style="padding-left: 10px !important; padding-right: 10px !important">
                        <v-icon small>mdi-filter</v-icon>
                        Filtrar
                        <v-icon small right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                     <v-list>
                        <v-list-item v-for="(filter,i) in filters_head" :key="i"  @click="sort_promo = filter.value">
                          <v-list-item-title v-text="filter.text"></v-list-item-title>
                        </v-list-item>
                      </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="px-0">
                  <v-data-footer
                    style="border:none !important"
                    :pagination="pagination"
                    :options="options"
                    @update:options="updateOptions"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.data-table-select="{ isSelected, select }">
              <v-simple-checkbox color="info" :value="isSelected" @input="select($event)"></v-simple-checkbox>
            </template> -
             <template v-slot:header.imageurl>
              <v-icon color="blue-grey lighten-3" dark>mdi-image</v-icon>
            </template>
            <template v-slot:item.imageurl={item}>
             <v-avatar size="32">
                <img :src="storage+item.imageurl" />
              </v-avatar>
            </template>
            <template v-slot:item.name.es="{ item }">
              <span class="font-weight-bold">{{ item.name.es }}</span>
            </template>
            <template v-slot:item.publication_date="{ item }">
              <span class="font-italic blue-grey--text">{{ item.publication_date | smartDate}}</span>
            </template>
            <template v-slot:item.visibility="{ item }">
             <v-chip class="my-2" dark :color="item.visibility == 0 ? 'blue-grey' : 'success'" small v-text="item.visibility == 0 ? 'Borrador' : 'Público'"></v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="text-right">
                <v-btn icon color="primary lighten-1" @click="$router.push(url_edit+item.id)" v-if="$can('Editar promocion')">
                  <v-icon color="primary lighten-1">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon color="primary lighten-1"  @click="deletePromo(item)" v-if="$can('Borrar promocion')">
                  <v-icon color="primary lighten-1">mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>-->
          <v-row>
            <v-col v-for="(item, i) in promotions"
            :key="i" md="3" for="">
              <v-card>
                <v-card-title class="text-center">{{ item.name.es }}</v-card-title>
                <v-card-text class="text-center">
                  <img :src="storage+item.imageurl" style="margin: 0 auto;display:block;height: 150px;"><br>
                  <div class="mt-4"></div>
                  <span class="font-italic blue-grey--text">{{ item.publication_date | smartDate}}</span>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-around">
                  <v-btn color="primary lighten-1" large @click="$router.push(url_edit+item.id)" v-if="$can('Editar menu')">
                    <v-icon color="secundary">mdi-pencil</v-icon> Editar
                  </v-btn>
                  <v-btn color="primary lighten-1" large @click="deletemenu(item)" v-if="$can('Borrar menu')">
                    <v-icon color="secundary">mdi-trash-can-outline</v-icon> Eliminar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'promotions',
    data () {
      return {
        search:'',
        selected_promo: [],
        load_promo: false,
        roles:[],
        promotions: [],
        restaurant_id: '',
        filter_status:'todo',
        sort_promo: '',
        headers: [
          {
            text: '',
            value: 'imageurl',
            sortable: false,
            width: '60',
            align: 'center',
          },
          { text: 'Nombre', value: 'name.es', class:"primary--text" },
          { text: 'Visibilidad', value: 'visibility', class:"primary--text" },
          { text: 'Fecha', value: 'publication_date' , class:"primary--text"},
          { text: '', value: 'actions' , class:"primary--text", width: '150'},
        ],
        url_edit: '',
        storage: '',
      }
    },
    created(){
      if(this.$store.getters['auth/isAdmin']){
        this.restaurant_id = this.$route.params ? this.$route.params.id : '';
        this.url_edit = `/tc-admin/restaurante/${this.restaurant_id}/administrar/promocion/`;
      }
      else{
        this.restaurant_id =  this.$store.getters['auth/restaurant'];
        this.url_edit = `/tc-admin/promociones/`;
      }
      this.storage = process.env.VUE_APP_IMG_URL_API + 'images/promotions/';
      this.getPromotions();
    },
    methods: {
      async getPromotions(){
        this.load_promo= true;
        this.promotions = [];
        try{
          const {data} = await this.$api.get(`api/promotions?restaurant_id=${this.restaurant_id}`);

          this.promotions = data.data.promotions;

          this.load_promo= false;
        }
        catch(error){
          this.load_promo= false;
          this.snackbar = {
            text: 'Lo sentimos, hubo un error al intentar obtener los usuarios',
            type: 'error',
            active:true,
          };

        }
      },

      async deletePromo(item){
        const msj = "Se eliminaran permanentemente los datos de la promoción " + item.name.es;

        const confirm = await this.$root.$confirm('¿Estas Seguro de Eliminar?',msj, { type: 'delete', btnConfirmText: 'Si, Eliminalo', btnCancelText: 'Descartar' });

        if(confirm){
          this.load_promo= true;
          const url = 'api/promotions/' + item.id;
          try{
            const {data} = await this.$api.delete(url);
            this.load_promo= false;
            this.getPromotions();
            this.search = '';
            this.$root.$snakbar({
              text: data.message,
              type: 'success',
              active: true
            });
          }
          catch(error){
            this.load_promo= false;
            if(error.response.status == "400"){
              this.$root.$snakbar({
                text: error.response.data.message,
                type: 'error',
                active: true
              });
            }
            else{
              this.$root.$snakbar({
                text: 'Lo sentimos, hubo un error al intentar eliminar la Promoción.',
                type: 'error',
                active: true
              });
            }
          }
        }
      },
    },
    computed:{
      filters_head(){
        return this.headers.filter(item => item.text != '');
      },
      statusCount(){
        return {
          public: this.promotions.filter(item => item.visibility == 1).length,
          eraser: this.promotions.filter(item => item.visibility == 0).length
        }
      },
      promotions_filter(){
        return this.promotions.filter(item =>{
          if(this.filter_status == 'todo')
            return true

          return item.visibility == this.filter_status
        });
      }
    }
  }
</script>
<style scope>
  .active-filter{
    font-weight: bold;
    color: #283145 !important;
    background: white !important;
  }
  .active-filter:before {
    background-color: transparent !important;
  }

</style>
